import styles from "./Experience.module.css";
import React from "react";

function Experience() {
  return (
    <section id="experience" className={styles.experience}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.header}>Experience</h2>
        </div>
        <div className={styles.containerColumns}>
          <div className={styles.sectionOne}>
            <div className={styles["text-container"]}>
              <p>               
              </p>
              <h3 className={styles.subHeader}>Junior Software Engineer</h3>
              <p className={styles.textp}>
                <b>
                  <em>Weblfleet Solution</em>
                  <br/>
                  <em>2023-05 - present</em>
                </b>
                <br/>
                <ul>
                  <li>Collaborated on software development tasks as a Junior Software Engineer</li>
                  <li>Contributed to project planning and implementation</li>
                  <li>Participated in code reviews and quality assurance processes</li>
                </ul>
              </p>
              <br/>

              <h3 className={styles.subHeader}>Software Engineer Intern</h3>
              <p className={styles.textp}>
                <b>
                  <em>Weblfleet Solution</em>
                  <br/>
                  <em>2022-09 - 2023-05</em>
                </b>
                <br/>
                <ul>
                <li>Performed software troubleshooting, delivered, analyzed and resolve technical issues</li>
                <li>Received daily mentoring and participated in meetings from sprint planning to application architecture planning</li>
                <li>Maintained and enhanced the automated build and deploy process for applications</li>
                <li>Wrote clean, maintainable, scalable and well-tested code</li>
                </ul>
                <h3 className={styles.techstack}>Tech-stack</h3>
                <ul>
                <li>Java - Spring framework, Spring Boot, JUnit, Hibernate, Gradle</li>
                <li>Oracle SQL, Swagger API, Kafka, Kubernetes, Docker, Azure, Grafana, Jira, Jenkins, Bitbucket, Git</li>
                <li>React, Redux, Node, Webpack, Jest </li>
                </ul>
              </p>

              <div className={styles.divider}></div>
              <div>
          <h2 className={styles.education}>Education</h2>
        </div>
        <h3 className={styles.subHeader}>Polish-Japanese Academy of Information Technology
</h3>
                <b>
                  <em> Bachelor of Engineering in Computer Science <br/>2021 - 2025</em> 
                </b>
               
                <p>
                The Bachelor's program in Computer Science and Information Technologies focuses on developing practical and theoretical skills in key areas like programming, mathematical analysis, and computer architecture. Emphasizing the integration of technology in business environments, this course prepares students for challenges in the tech industry, equipping them with the knowledge to design and manage information systems effectively.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;

/*      <p className={styles.textp}>
              <div className={styles["container-icon"]}>
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original-wordmark.svg"
                  alt="icon"
                  width={80}
                />
                   <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jenkins/jenkins-original.svg"
                  alt="icon"
                  width={80}
                />
                   <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bitbucket/bitbucket-original-wordmark.svg"
                  alt="icon"
                  width={80}
                />
                   <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original-wordmark.svg"
                  alt="icon"
                  width={80}
                />
                   <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original-wordmark.svg"
                  alt="icon"
                  width={80}
                />
                   <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original-wordmark.svg"
                  alt="icon"
                  width={80}
                />
              </div>
              </p> */