import styles from "./Projects.module.css";

function Projects() {
  return (
    <section id="projects" className={styles.container}>
      <h2 className={styles.header}>My Portfolio</h2>
      <div className={styles.wrapper}>
        <div className={styles.wrapperOne}>
          <div className={styles.project}>
            <div className={styles.text}>
              <h3 className={styles.name}>Fashion shop</h3>
              <p>In progress</p>
              <h4 className={styles.font}>
                React/MongoDB/Java/Spring Boot
              </h4>
            </div>
            <img
              src="/images/fashion.jpg"
              alt="fashion_website"
              className={styles.images}
            />
            <a
              href="https://github.com/martulka11/Fashion_Shop"
              className={styles.link}
            >
              Go to website
            </a>
          </div>
          <div className={styles.project}>
            <div className={styles.text}>
              <h3 className={styles.name}>Getaway Website</h3>
              <h4>JS/Express/Mapbox/MongoDB</h4>
            </div>
            <img
              src="/images/getaway.jpg"
              alt="getaway_website"
              className={styles.images}
            />
            <a
              href="https://github.com/martulka11/Getaway"
              className={styles.link}
            >
              Go to website
            </a>
          </div>
        </div>
        <div className={styles.wrapperTwo}>
          <div className={styles.project}>
            <div className={styles.text}>
              <h3>To do list</h3>
              <h4>React/MongoDB/Next.js</h4>
            </div>
            <img
              src="/images/todolist.jpg"
              alt="todolist_website"
              className={styles.images}
            />
            <a
              href="https://github.com/martulka11/To-Do_List"
              className={styles.link}
            >
              Go to website
            </a>
          </div>
          <div className={styles.project}>
            <div className={styles.text}>
              <h3>Cocktails Recipes</h3>
              <h4>React/API Rest</h4>
            </div>
            <img
              src="/images/cocktails3.jpg"
              alt="cocktails_website"
              className={styles.images}
            />
            <a
              href="https://github.com/martulka11/Drinks-API-Rest"
              className={styles.link}
            >
              Go to website
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}




export default Projects;
