import styles from "./Header.module.css";

function Header() {
  return (
    <section id="header" className={styles.triangle}>
      <header
        className={styles.header}
        style={{
          backgroundImage: "url(/images/niebo.jpg)",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          height: "58rem",
          
        }}
      >
        <div className={styles.container}>
          <div className={styles.font}>
            <p className={styles.title}>Hi, I'm Marta Latoch</p>
            <p>
              I'm a Full-stack Developer with an artistic soul and a passion for
              traveling.
            </p>
          </div>
          <div className={styles.icon}>
            <a href="https://github.com/martulka11">
              <img alt="github" src="./icons/github-logo.png" />
            </a>
            <a href="https://www.facebook.com/marta.latoch.7/">
              <img alt="facebook" src="./icons/facebook.png" />
            </a>
            <a href="https://www.instagram.com/martuuulkaa/">
              <img alt="instagram" src="./icons/instagram.png" />
            </a>
            <a href="https://www.linkedin.com/in/marta-latoch-341b13237/">
              <img alt="linkedin" src="./icons/linkedin.png" />
            </a>
          </div>
        </div>

        <div className={styles["photo-wrapper"]}>
          <span className={styles.rectangle}>
          </span>
          <div className={styles.photome}>
            <img alt="me" src="/images/photoofme1.jpg" />
          </div>
        </div>
      </header>
    </section>
  );
}

export default Header;
