import styles from "./About.module.css";
import React from "react";

function About() {
  return (
    <section id="about" className={styles.about}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.header}>About me</h2>
        </div>
        <div className={styles.containerColumns}>
          <div className={styles.sectionOne}>
            <div className={styles["text-container"]}>
              <p>
                I am <b>full-stack developer</b> from Poland with passion to
                coding. I am studying Computer Science in Warsaw and working as  
                Software Engineer. I independently expand my knowledge in
                frontend technologies like <b>React, JavaScript</b>, backend technologies 
                like <b>Java</b>. I try to
                improve my programming skills every day to become a better
                programmer with more and more ease to solve problems and expand
                my range of knowledge in order to become a valued fullstack in
                the future.
                <br />
                <br />
                Embracing the digital nomad lifestyle, I fulfill my dream of traveling, 
                which my remote work facilitates. This lifestyle not only allows me to 
                explore new horizons but also to engage in my hobbies like painting and 
                sports - windsurfing, surfing, and cycling, which are my sources of inspiration and energy.
                Combining so many tasks (travelling, studying and working) taught me good time management and conscientiousness.
                <br />
              </p>
            
            </div>
            <div className={styles["container-details"]}>
              <div>
                <h4 className={styles.headerTwo}>Contact Details</h4>
                <span>Marta Latoch</span>
                <br />
                <span>Warsaw / Remote</span>
                <br />
                <span>marta.latoch@yahoo.com</span>
              </div>
              <div className={styles["cv-button"]}>
                <p>
                  <a
                    href="/Resume.pdf"
                    download="CV Marta Latoch"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img src="./icons/download-solid.svg" alt="download-icon" />
                    Download Resume{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className={styles.sectiontwo}>
            <img src="/images/bali.jpg" alt="majorka" />
            <img src="/images/obraz.jpg" alt="obraz" />
            <img src="/images/gory.jpg" alt="gory" />
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>

    </section>
  );
}

export default About;
