import React from "react";
import About from "./components/About";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Header from "./components/Header";

function App() {
  
  return (
    <React.Fragment>
      <Navbar />
      <main>
        <Header />
        <About />
        <Experience/>
        <Projects />
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default App;
