import styles from "./Navbar.module.css";

function Navbar() {
  function hidden() {
    if (window.innerWidth > 720) {
      return true;
    }
  }

  return (
    <nav className={styles.nav}>
      {hidden() && (
        <div id="name">
          <a href="#header">Marta</a>
        </div>
      )}
      <div>
        <a href="#about">About</a>
        <a href="#projects">Projects</a>
      </div>
    </nav>
  );
}

export default Navbar;
