import styles from "./Footer.module.css";

function Footer() {
  return (
    <section id="footer" className={styles.footer}>
      <p>©2022 Designed & coded by Marta Latoch </p>
    </section>
  );
}

export default Footer;
